.faqPage {
  margin: 50px 0px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.faqHeader {
  margin-top: 50px;
  text-align: center;
  margin-bottom: 50px;
}

.faqTitle {
  color: #0070ba;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 24px;
  display: inline-block;
}

@media (max-width: 425px) {
  .faqPage {
    margin: 30px 0px;
  }

  .faqHeader {
    margin-top: 30px;
    text-align: center;
    margin-bottom: 30px;
  }
}
