.accordion-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion {
  width: 70%;
  max-width: 800px;
}

.accordion-section {
  border: 1px solid #0070ba;
  border-radius: 20px;
  margin-bottom: 20px;
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  color: #000;
  font-size: 20px;
  font-weight: bold;
}

.accordion-title.active {
  background-color: #ddd;
  border-radius: 20px;
}

.title-text {
  flex-grow: 1;
}

.accordion-content {
  display: none;
  color: #999;
  font-size: 20px;
  padding: 20px;
  text-align: left;
}

.accordion-content.active {
  display: block;
}

.iconOpen {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #0070ba;
  margin-left: 10px;
}

.iconClose {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  color: #0070ba;
  margin-left: 10px;
}

@media (max-width: 375px) {
  .accordion-title {
    font-size: 16px;
  }

  .accordion-content {
    font-size: 16px;
  }
}
