.footer {
  background-color: #0070ba;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.footerInner {
  padding: 70px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.footerBlock {
  width: 33.33333%;
  padding: 0 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.navLink {
  color: rgba(0, 4, 15, 0.8);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  opacity: 1;
  position: relative;
  margin: 0px 0px 15px 0px;
  transition: opacity 0.2s linear;
}

.navLink:hover {
  opacity: 0.75;
  cursor: pointer;
}

.navLink:last-child {
  margin: 0px;
}

.contactInfo {
  display: flex;
  flex-direction: column;
}

.contactInfo a {
  color: #fff;
  display: inline-flex;
  align-items: center;
  text-align: left;
  font-size: 16px;
}

.adress {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.phone {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.email {
  display: flex;
  align-items: center;
}

.iconMap {
  font-size: 30px;
  margin-right: 20px;
  color: #fff;
}

.iconTel {
  font-size: 30px;
  margin-right: 20px;
  color: #fff;
}

.iconMail {
  font-size: 30px;
  margin-right: 20px;
  color: #fff;
}

.iconPayments {
  font-size: 28px;
  color: #fff;
}

.copyright {
  background-color: #0070ba;
}

.copyrightText {
  padding: 15px 0px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}

.documents {
  text-align: left;
}

.documents a {
  color: #fff;
  font-size: 16px;
}

.aboutPrivacyPolicy {
  margin-bottom: 25px;
}

.aboutOferta {
  margin-bottom: 25px;
}

.footerLogos {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  margin-top: 20px; 
}

.footerLogoItem {
  margin: 0 10px; 
}

@media (max-width: 768px) {
  .footerInner {
    padding: 50px 0;
  }

  .footerBlock {
    width: 100%;
  }

  .footerContent {
    padding: 0px 0px 30px 0px;
  }

  .contactInfo {
    align-items: center;
  }

  .contactInfo a {
    text-align: center;
  }

  .documents {
    text-align: center;
  }
}
