.map {
  background: #0070ba;
  margin: 50px 0px 0px 0px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.mapInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0px 100px 0px;
}

.mapContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 30px 0px 0px;
}

.mapHeader {
  text-align: center;
  padding: 50px 0px 0px 0px;
}

.mapTitle {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 24px;
  display: inline-block;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.contactInfo a {
  color: #fff;
  display: inline-flex;
  align-items: center;
}

.adress {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.phone {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.email {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.iconMap {
  font-size: 30px;
  margin-right: 15px;
  color: #fff;
}

.iconTel {
  font-size: 25px;
  margin-right: 15px;
  color: #fff;
}

.iconMail {
  font-size: 35px;
  margin-right: 15px;
  color: #fff;
}

iframe {
  width: 100%;
  height: 400px;
}

@media (max-width: 768px) {
  .mapInner {
    flex-direction: column;
  }

  .contactInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .mapContent {
    padding: 0px 0px 30px 0px;
  }

  .mapTitle {
    text-align: center;
  }

  iframe {
    padding: 0px 0px 30px 0px;
  }

  .mapInner {
    padding: 50px 0px 50px 0px;
  }
}

@media (max-width: 425px) {
  .map {
    margin: 30px 0px 0px 0px;
  }

  .mapInner {
    padding: 30px 0px 30px 0px;
  }
}
