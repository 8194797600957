.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.header {
  position: relative;
  width: 100%;
  height: 70px;
  background-color: #f6f9fc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #0070ba;
}

.headerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerLogo {
  cursor: pointer;
}

.contactInfo {
  display: flex;
  flex-direction: column;
}

.contactInfo a {
  color: #2c2f2e;
  display: inline-flex;
  align-items: center;
}

.phone {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.wallet {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.wallet a {
  color: #000;
}

.icon {
  font-size: 35px;
  margin-right: 10px;
  color: #0070ba;
}

.header.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}

.nav {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.navLink {
  color: #999;
  font-weight: bold;
  opacity: 1;
  transition: opacity 0.2s linear;
  margin-left: 150px;
  position: relative;
}

.navLink:first-child {
  margin-left: 0px;
}

.navLink:hover {
  opacity: 0.75;
  cursor: pointer;
}

.navLink::before {
  content: "";
  display: block;
  width: 100%;
  height: 5px;
  opacity: 0;
  background-color: #0070ba;

  position: absolute;
  top: -25px;
  left: 0;
  z-index: 1;

  transition: opacity 0.25s linear;
}

.navLink:hover::before {
  opacity: 1;
}

.burger {
  display: none;
  cursor: pointer;
  background: none;
  border: 0;
}

.burgerItem {
  display: block;
  width: 30px;
  height: 2px;
  background-color: #2c2f2e;
  color: transparent;
  position: relative;
}

.burger.show .burgerItem {
  background-color: transparent;
}

.burgerItem::before,
.burgerItem::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #2c2f2e;
  position: absolute;
  left: 0;
  z-index: 1;
}

.burgerItem::before {
  top: -8px;
  transition: transform 0.3s ease-out;
}

.burgerItem::after {
  bottom: -8px;
  transition: transform 0.3s ease-out;
}

.burger.show .burgerItem::before {
  transform-origin: left top;
  transform: rotate(45deg) translate3d(0px, -2px, 0);
}

.burger.show .burgerItem::after {
  transform-origin: left bottom;
  transform: rotate(-45deg) translate3d(0, 3px, 0);
}

@media (max-width: 1024px) {
  .container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
  }

  .header {
    transition: all 1s ease;
  }

  .header.fixed {
    position: fixed;
  }

  .headerInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .burger {
    display: block;
  }

  .nav {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: left;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-color: #f6f9fc;
    z-index: 99999;
  }

  .nav.show {
    opacity: 1;
    visibility: visible;
  }

  .navLink {
    padding: 15px;
    margin: 0;
    font-size: 18px;
  }

  .navLink a {
    color: #2c2f2e;
  }

  .navLink:hover::before {
    opacity: 0;
  }
}

@media (max-width: 375px) {
  .navLink a {
    font-size: 12px;
  }
}
